<template>
	<div class="rootDiv">
		<div class="retrieve">
			<div class="searchDiv flex flex-jb">
				<div>
					<el-select v-model="ruleForm.exType" placeholder="请选择礼包类型" clearable>
						<el-option v-for="item in exTypes" :key="item.value" :label="item.key" :value="item.value"
							v-if="userFlag == false?item.value!=2&&item.value!=3:item.value !== null && item.value !== undefined"></el-option>
					</el-select>
					<!--          <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450" placeholder="请输入"
            v-model="retrieveForm.searchValue"> -->
					<el-button class="fc-0075F6" slot="append" type="primary" icon="el-icon-search" @click="submitSearch">
					</el-button>
					<!-- </el-input> -->
				</div>
				<div class="flex flex-ac">
					<div class="fs-16 fc-6b9eff pointer" @click="addList">
						<i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
					</div>
					<div class="ml-40 fc-666 fs-16 pointer" v-if="activeName == '福利道具'" @click="getCutPage('福利时间')">福利时间</div>
					<div class="ml-40 fc-666 fs-16 pointer" v-if="activeName == '福利时间'" @click="getCutPage('福利道具')">返回</div>
				</div>
			</div>
		</div>
		<TableHeightFull class="tableDiv pall-30">
			<template v-if="activeName == '福利时间'">
				<EleTable ref="tableRef1" slot="table" :tableData="tableData" key="table1" :columns="columns" height="100%"
					:border="false" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
				</EleTable>
			</template>
			<template v-if="activeName == '福利道具'">
				<EleTable ref="tableRef2" slot="table" :tableData="tableData" key="table2" :columns="wealColumns" height="100%"
					:border="false" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
					<template v-slot:img="scope">
						<img :src="scope.row.propImg" alt="" class="smallImg">
					</template>
				</EleTable>
			</template>
			<PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
		</TableHeightFull>
		<!-- 弹框 -->
		<el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" :top="activeName == '福利时间'?'15vh':'10vh'"
			width="30%" @close="resetForm">
			<template v-if="activeName == '福利时间'">
				<el-form :model="ruleForm" ref="ruleForm" label-width="100px">
					<el-form-item label="开始时间：" prop="startDate" :rules="{required: true, message: '请选择日期', trigger: 'change'}">
						<el-time-picker class="w-full" v-model="ruleForm.startDate" format="HH:mm" value-format="HH:mm"
							placeholder="任意时间点">
						</el-time-picker>
					</el-form-item>
					<el-form-item label="结束时间：" prop="endDate" :rules="{required: true, message: '请选择日期', trigger: 'change'}">
						<el-time-picker class="w-full" v-model="ruleForm.endDate" format="HH:mm" value-format="HH:mm"
							placeholder="任意时间点">
						</el-time-picker>
					</el-form-item>
				</el-form>
			</template>
			<template v-if="activeName == '福利道具'">
				<el-form :model="ruleForm" ref="ruleForm" label-width="100px">
					<el-form-item label="道具名称" prop="propName" v-if="'propName' in ruleForm"
						:rules="{required: true, message: '道具名称不能为空', trigger: 'blur'}">
						<el-input v-model="ruleForm.propName"></el-input>
					</el-form-item>
					<el-form-item label="礼包类型" prop="exType" v-if="'exType' in ruleForm"
						:rules="{required: true, message: '请至少选择一个礼包', trigger: 'change'}">
						<el-select v-model="ruleForm.exType" placeholder="请选择">
							<el-option v-for="item in exTypes" :key="item.value" :label="item.key" :value="item.value"
								v-if="userFlag == false?item.value!=2&&item.value!=3:item.value !== null && item.value !== undefined"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="道具类型" prop="propType" v-if="'propType' in ruleForm"
						:rules="{required: true, message: '请选择道具类型', trigger: 'change'}">
						<el-select v-model="ruleForm.propType" placeholder="请选择">
							<el-option v-for="item in propTypes" :key="item.value" :label="item.key" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="道具图片" prop="propImg" v-if="'propImg' in ruleForm">
						<UploadFile upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
							iconName="el-icon-upload" iconSize="26" v-model="ruleForm.propImg"></UploadFile>
					</el-form-item>
					<el-form-item label="道具数量" prop="num" v-if="'num' in ruleForm"
						:rules="{required: true, message: '道具数量不能为空', trigger: 'blur'}">
						<el-input type="number" v-model="ruleForm.num"></el-input>
					</el-form-item>
					<el-form-item label="有效天数" prop="validDay" v-if="'validDay' in ruleForm"
						:rules="{required: true, message: '有效天数不能为空', trigger: 'blur'}">
						<el-input type="number" v-model="ruleForm.validDay"></el-input>
					</el-form-item>
					<el-form-item label="优惠券面额" prop="money" v-if="'money' in ruleForm && ruleForm.propType == 4"
						:rules="{required: true, message: '优惠券面额不能为空', trigger: 'blur'}">
						<el-input type="number" v-model="ruleForm.money"></el-input>
					</el-form-item>
					<el-form-item label="优惠券类型" prop="couponsType" v-if="'couponsType' in ruleForm && ruleForm.propType == 4"
						:rules="{required: true, message: '请至少选择一个优惠劵类型', trigger: 'change'}">
						<el-select v-model="ruleForm.couponsType" placeholder="请选择">
							<el-option v-for="item in couponsTypes" :key="item.value" :label="item.key" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="使用门槛" prop="doorSill" v-if="'doorSill' in ruleForm && ruleForm.propType == 4"
						:rules="{required: true, message: '使用门槛不能为空', trigger: 'blur'}">
						<el-input type="number" v-model="ruleForm.doorSill"></el-input>
					</el-form-item>
					<el-form-item label="排序" prop="sort" v-if="'sort' in ruleForm">
						<el-input type="number" v-model="ruleForm.sort"></el-input>
					</el-form-item>
				</el-form>
			</template>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="affirmPopup" :loading="butLoading">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import indexMixin from "@/utils/indexMixin"
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'welfareDaily',
		mixins: [indexMixin],
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
			"UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
		},
		computed: {
			...mapState('menu', {
				'propTypes': state => state.dictList ? state.dictList.prop_type : [], //道具类型
				'couponsTypes': state => state.dictList ? state.dictList.coupons_type : [], //优惠劵类型
				'exTypes': state => state.dictList ? state.dictList.ex_type : [], //礼包类型
			})
		},
		data() {
			return {
				activeName: '福利道具',
				userFlag: false,
				retrieveForm: {
					searchValue: '',
				},
				tabLoading: false,
				butLoading: false,
				columns: [{
					type: 'index',
					label: '序号',
					width: '60',
					align: 'center'
				}, {
					label: '开始时间',
					prop: 'startDate',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '结束时间',
					prop: 'endDate',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					columnType: 'button',
					label: '操作',
					align: 'right',
					fixed: 'right',
					width: '150',
					buttons: [{
						type: 'text',
						text: '编辑',
						fn: this.editList,
					}, {
						type: 'text',
						text: '删除',
						fn: this.deleteList,
					}, ],
				}, ],
				wealColumns: [{
					label: '排序',
					prop: 'sort',
					align: 'center',
					width: '60'
				}, {
					columnType: 'custom',
					label: '道具图',
					prop: 'img',
					width: '80'
				}, {
					label: '道具名称',
					prop: 'propName',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					columnType: 'fun',
					label: '礼包类型',
					prop: 'exType',
					align: 'left',
					'show-overflow-tooltip': true,
					formatData: (e) => {
						let ele = this.exTypes.find(ele => ele.value == e)
						return ele ? ele.key : e
					}
				}, {
					columnType: 'fun',
					label: '道具类型',
					prop: 'propType',
					align: 'left',
					'show-overflow-tooltip': true,
					formatData: (e) => {
						let ele = this.propTypes.find(ele => ele.value == e)
						return ele ? ele.key : e
					}
				}, {
					label: '兑换数量',
					prop: 'num',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '有效天数',
					prop: 'validDay',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '面额',
					prop: 'money',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '门槛',
					prop: 'doorSill',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					columnType: 'fun',
					label: '优惠劵类型',
					prop: 'couponsType',
					align: 'left',
					'show-overflow-tooltip': true,
					formatData: (e) => {
						let ele = this.couponsTypes.find(ele => ele.value == e)
						return ele ? ele.key : e
					}
				}, {
					label: '创建时间',
					prop: 'createDate',
					align: 'left',
					width: '170',
					'show-overflow-tooltip': true
				}, {
					columnType: 'button',
					label: '操作',
					align: 'right',
					fixed: 'right',
					width: '120',
					buttons: [{
						type: 'text',
						text: '编辑',
						fn: this.editList
					}, {
						type: 'text',
						text: '删除',
						fn: this.deleteList
					}],
				}, ],
				tableData: [],
				//弹框
				dialogTitle: '新增',
				dialogFormVisible: false,
				ruleForm: {
					// 福利时间
					startDate: '',
					endDate: '',
					// 福利道具
					exType: '', //0福利道具  1新人礼包
					propName: '', //道具名称
					propType: '', //道具类型
					propImg: '', //道具图片
					num: '', //道具数量
					validDay: '', //有效天数
					money: '', //优惠券面额
					doorSill: '', //使用门槛
					couponsType: '', //优惠券类型
					sort: '',
				},
			}
		},
		mounted() {
			this.getTableData()
		},
		created() {
			//判断是否为admin
			let userInfo = JSON.parse(localStorage.getItem('userInfo'))
			if (userInfo.id == 3) {
				this.userFlag = true
			}
		},
		methods: {
			//搜索
			submitSearch() {
				this.pageInfo.pageNum = 1
				this.getTableData(this.ruleForm.exType)
			},
			//切换页面
			getCutPage(name) {
				this.activeName = name
				this.getTableData()
				// this.$refs['tableRef'].doLayout()
			},
			//获取表格数据
			getTableData(type) {
				this.tabLoading = true
				if (this.activeName == '福利时间') {
					this.$http.get('/dayActy/findDayActy').then(({
						data: result
					}) => {
						this.tabLoading = false
						this.tableData = result.data
					}).catch((err) => {
						this.tabLoading = false
					})
				} else if (this.activeName == '福利道具') {
					this.$http.get('/dayActyProp/findDayActyPropList', {
						params: {
							currentPage: this.pageInfo.pageNum,
							pageSize: this.pageInfo.pageSize,
							exType: type || type == 0 ? type : '',
						}
					}).then(({
						data: result
					}) => {
						this.tabLoading = false
						this.tableData = result.data.list
						this.pageInfo.total = result.data.total
					}).catch(err => {
						this.tabLoading = false
					})
				}
			},
			//新增列表
			addList() {
				this.dialogTitle = '新增'
				if (this.activeName == '福利时间') {
					this.ruleForm = {
						startDate: '',
						endDate: '',
					}
				} else if (this.activeName == '福利道具') {
					let sort = '',
						tableData = this.tableData
					if (tableData.length > 0) {
						if (tableData[0].sort) {
							sort = tableData[0].sort + 1
						}
					}
					this.ruleForm = {
						exType: '',
						propName: '',
						propImg: '',
						propType: '',
						num: '',
						validDay: '',
						money: '',
						doorSill: '',
						couponsType: '',
						sort: sort,
					}
				}
				this.dialogFormVisible = true
			},
			//删除
			deleteList(row) {
				this.$confirm('您确认删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					let apiUrl = ''
					if (this.activeName == '福利时间') {
						apiUrl = "/dayActy/removeDayActy"
					} else if (this.activeName == '福利道具') {
						apiUrl = "/dayActyProp/removeDayActyProp"
					}
					this.$http.post(apiUrl, {
						id: row.id
					}).then(res => {
						this.getTableData()
						this.$message({
							message: '删除成功',
							type: 'success'
						})
					})
				}).catch(_ => {})
			},
			//跳转详情
			editList(row) {
				this.dialogTitle = '编辑'
				this.ruleForm = JSON.parse(JSON.stringify(row))
				this.dialogFormVisible = true
			},
			//确认弹框
			affirmPopup() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.butLoading = true
						let apiUrl = "",
							ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
						if (this.activeName == '福利时间') {
							if (this.dialogTitle == '新增') {
								apiUrl = "/dayActy/saveDayActy"
							} else if (this.dialogTitle == '编辑') {
								apiUrl = "/dayActy/editDayActy"
							}
						} else if (this.activeName == '福利道具') {
							if (this.dialogTitle == '新增') {
								apiUrl = "/dayActyProp/saveDayActyProp"
							} else if (this.dialogTitle == '编辑') {
								apiUrl = "/dayActyProp/editDayActyProp"
							}
							if (ruleForm.propType != 4) {
								ruleForm.money = ''
								ruleForm.doorSill = ''
								ruleForm.couponsType = ''
							}
						}
						this.$http.post(apiUrl, ruleForm).then(({
							data: result
						}) => {
							this.$message({
								message: this.dialogTitle + '成功',
								type: 'success'
							})
							this.getTableData()
							this.butLoading = false
							this.dialogFormVisible = false
						}).catch(err => {
							this.butLoading = false
						})
					}
				})
			},
			//重置清除校验
			resetForm() {
				Object.assign(this.$data.ruleForm, this.$options.data().ruleForm)
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate()
				})
			},
		},
	}
</script>
<style lang="less" scoped>
</style>